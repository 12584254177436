export default class Attendance {
  recordId: string;
  employeeId: string;
  affiliationId: string;
  targetShiftId: string;
  shiftpatternid: string;
  attendanceDate: string;
  startTime: string;
  endTime: string;
  movingDistance: string;
  breaktime: string;
  work: string;
  over: string;
  midnight: string;
  hadmeal: string;
  assistFlag: string;
  constructor(
    recordId: string,
    employeeId: string,
    affiliationId: string,
    targetShiftId: string,
    shiftpatternid: string,
    attendanceDate: string,
    startTime: string,
    endTime: string,
    movingDistance: string,
    breaktime: string,
    work: string,
    over: string,
    midnight: string,
    hadmeal: string,
    assistFlag: string
  ) {
    this.recordId = recordId;
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.targetShiftId = targetShiftId;
    this.shiftpatternid = shiftpatternid;
    this.attendanceDate = attendanceDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.movingDistance = movingDistance;
    this.breaktime = breaktime;
    this.work = work;
    this.over = over;
    this.midnight = midnight;
    this.hadmeal = hadmeal;
    this.assistFlag = assistFlag;
  }
}
