<template>
  <div>
    <div class="text-center">
      <!-- <p class="text-h3">{{ $store.state.name }}</p> -->
      <v-autocomplete
        v-model="selectShozoku"
        :items="shozokuList"
        label="事業所を選択してください！"
        item-text="sname"
        item-value="incode"
        class="mx-4 attendance-sel"
        flat
        outlined
        :readonly="shozokuListReadOnly"
        @change="changeSelectShozoku()"
      >
      </v-autocomplete>
      <p class="text-h3">
        {{ date.toLocaleDateString() }}{{ &nbsp; }}
        {{ date.toLocaleTimeString() }}
      </p>
    </div>

    <div class="text-center ma-10">
      <Btn
        class="mr-16"
        icon="mdi-briefcase-clock"
        color="success"
        x-big
        rounded
        @click="setAttendance(false)"
        fontSize="50"
        >出勤</Btn
      >
      <Btn
        class="ml-16"
        icon="exit-run"
        color="error"
        x-big
        rounded
        @click="setAttendance(true)"
        fontSize="50"
        >退勤</Btn
      >
    </div>

    <div class="text-center">
      <Btn
        class="mx-5"
        middle
        rounded
        icon="briefcase-edit"
        value="修正"
        fontSize="30"
        @click="click(Actions.Fix)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="file-table"
        value="勤務実績"
        fontSize="30"
        @click="click(Actions.Roster)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="chart-timeline"
        value="個別シフト"
        font-size="26"
        @click="click(Actions.Shift)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="mdi-account-key"
        value="PWD変更"
        font-size="30"
        v-if="isShow()"
        @click="click(Actions.Password)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="mdi-car"
        value="移動登録"
        font-size="30"
        v-if="isShow()"
        @click="click(Actions.WelfareMove)"
      />
    </div>
    <div class="text-center pt-10">
      <p class="text-h3">
        {{ this.$store.state.supplement }}
      </p>
    </div>
  </div>
</template>

<script>
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

//dialogs
import AttendanceDialog from "@/views/dialogs/AttendanceDialog";
import TemporaryLogin from "@/views/dialogs/TemporaryLogin.vue";
import AttendanceFixTemporaryDialog from "@/views/dialogs/AttendanceFixTemporaryDialog";
import AttendanceDetail from "@/models/AttendanceDetail";
import TemporaryAttendanceDetailDialog from "@/views/dialogs/TemporaryAttendanceDetailDialog";
import TemporaryShiftDialog from "@/views/dialogs/TemporaryShiftDialog";
import TemporaryPasswordChangeDialog from "@/views/dialogs/TemporaryPasswordChangeDialog";
import TemporaryDistanceDialog from "@/views/dialogs/TemporaryDistanceDialog";

import { BUSYO_CODE as BusyoCode } from "@/defines";

const DATE = new Date();

const Actions = {
  Eat: 0,
  Break: 1,
  MidBreak: 2,
  Fix: 3,
  Roster: 4,
  Shift: 5,
  Password: 6,
  WelfareMove: 7
};

export default {
  name: "Office",
  mixins: [Common, ShowDialogs, Api],
  data: () => ({
    date: DATE,
    targetdate: `${DATE.getFullYear()}-${DATE.getMonth() +
      1}-${DATE.getDate()}`,
    Actions,
    selectShozoku: "",
    shozokuList: [],
    shozokuListReadOnly: false
  }),
  methods: {
    isShow() {
      // if (this.$store.state.shozokuCode1 === BusyoCode.WELFARE) {
      //   return true;
      // }
      return false;
    },
    async click(action) {
      console.log(action);

      if (!this.selectShozoku) {
        this.$error("事業所を選択してください！");
        return;
      }

      if (!this.$store.state.user.login) {
        const result = await this.$showDialog(TemporaryLogin);
        console.log(result);

        if (!result) return;
      }

      switch (action) {
        case Actions.Fix:
          await this.fix();
          break;
        case Actions.Roster:
          await this.roster();
          break;
        case Actions.Shift:
          await this.shift();
          break;
        case Actions.Password:
          await this.pwdchange();
          break;
        case Actions.WelfareMove:
          await this.setdistance();
          break;
      }
    },
    async fix() {
      await this.updateDate();
      const ts = this.date.getTime();
      const param = {
        // incode: this.$store.state.incode,
        incode: this.selectShozoku,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      const resp = await this.$get(
        this.Paths.attendance,
        "temporary=" + encode
      );

      const args = new AttendanceDetail({
        recordid: resp.recordid ? resp.recordid : "",
        employee: resp.employee ? resp.employee : this.$store.state.user.incode,
        affiliation: resp.affiliation ? resp.affiliation : this.selectShozoku,
        shiftid: resp.shiftid ? resp.shiftid : "",
        shiftpatternid: resp.shiftpatternid ? resp.shiftpatternid : "",
        datestr: this.targetdate,
        date: ts,
        start: resp.start ? resp.start : "",
        end: resp.end ? resp.end : "",
        distance: resp.distance ? resp.distance : "0",
        inover: resp.inover ? resp.inover : "",
        lateleave: resp.lateleave ? resp.lateleave : "",
        amount: resp.amount ? resp.amount : "",
        breakflg: resp.breakflg ? resp.breakflg : "",
        daycount: resp.daycount ? resp.daycount : "",
        nightcount: resp.nightcount ? resp.nightcount : "",
        hadmeal: resp.hadmeal ? resp.hadmeal : "0",
        careShu: resp.careShu ? resp.careShu : "0",
        careKbn: resp.careKbn ? resp.careKbn : "0",
        timeKbn: resp.timeKbn ? resp.timeKbn : "0",
        assistFlag: resp.assistFlag ? resp.assistFlag : "0",
        otherFlag: resp.otherFlag ? resp.otherFlag : "0"
      });
      // 打刻時間を追加
      args.stampTime = resp.stampTime ? resp.stampTime : "";
      await this.$showDialog(AttendanceFixTemporaryDialog, { args });
    },
    async roster() {
      await this.updateDate();
      const param = {
        // incode: this.$store.state.incode,
        incode: this.selectShozoku,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      try {
        const item = await this.$get(
          this.Paths.attendance,
          "tmpattendance=" + encode
        );
        await this.$showDialog(TemporaryAttendanceDetailDialog, { args: item });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async shift() {
      const date = this.$getCrossServiceDate();

      const param = {
        userincode: this.$store.state.user.incode,
        targetmonth:
          parseInt(date.split("-")[0]) + "-" + parseInt(date.split("-")[1])
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      try {
        const item = await this.$get(this.Paths.shift, "tmpshift=" + encode);
        await this.$showDialog(TemporaryShiftDialog, { args: item });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async pwdchange() {
      // パスワード変更は不使用
      try {
        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code
        };
        await this.$showDialog(TemporaryPasswordChangeDialog, { args: param });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async setdistance() {
      // 移動登録は不使用
      try {
        const queryparam = {
          userincode: this.$store.state.user.incode,
          targetdate: this.targetdate
        };
        const encode = encodeURI(JSON.stringify(queryparam));
        const records = await this.$get(
          this.Paths.tmpDistance,
          "query=" + encode
        );

        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code,
          dataheaders: records.headers,
          databodies: records.body
        };
        await this.$showDialog(TemporaryDistanceDialog, { args: param });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async setAttendance(isEnd = false) {
      // 出退勤メッセージクリア
      this.$store.state.supplement = "";
      if (!this.selectShozoku) {
        this.$error("事業所を選択してください！");
        return;
      }
      // ダイアログ表示後に所属選択を初期化。登録はthis.$store.state.workIncodeから取得。
      // ただし、1件のみの場合は初期化を行わない。
      if (this.shozokuList.length > 1) this.selectShozoku = "";
      const result = await this.$showDialog(AttendanceDialog, { isEnd });
      console.log(result);
    },
    async updateDate() {
      this.date = new Date();
      this.targetdate = `${this.date.getFullYear()}-${this.date.getMonth() +
        1}-${this.date.getDate()}`;
    },
    async setShozokuList() {
      try {
        const queryparam = { incode: this.$store.state.incode };
        const encode = encodeURI(JSON.stringify(queryparam));
        const records = await this.$get(
          this.Paths.shozokuGroup,
          "query=" + encode
        );
        this.shozokuList = records;
        this.$store.state.workIncode = "";
        if (records.length === 1) {
          this.shozokuListReadOnly = true;
          this.selectShozoku = records[0].incode;
          this.$store.state.workIncode = records[0].incode;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    changeSelectShozoku() {
      // 事業所選択の変更時
      this.$store.state.workIncode = this.selectShozoku;
      this.$store.state.supplement = "";
    }
  },
  async created() {
    console.log("created");
    await this.setShozokuList();
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
::v-deep .attendance-sel .v-select__slot > label.v-label {
  font-size: 2.5rem !important;
  text-align: center;
  min-height: 100px;
  line-height: 65px;
}
::v-deep .attendance-sel .v-select__slot > input {
  padding-top: 20px;
  font-size: 2.5rem !important;
  text-align: center;
  min-height: 100px;
  line-height: 55px;
}
::v-deep div.v-list-item__content > div.v-list-item__title {
  font-size: 2.5rem !important;
  text-align: center;
}
::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  min-height: 70px;
}
</style>
