<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    fullscreen
    midium
    close
    headerButtonDisp
    @clickClose="close(false)"
  >
    <div>
      <v-data-table
        :headers="headers"
        :items="bodies"
        :items-per-page-options="20"
        class="elevation-1"
      ></v-data-table>
    </div>
  </DialogBase>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

export default {
  name: "TemporaryShiftDialog",
  mixins: [Common, Dialog, Forms, Api],
  data: () => ({
    headers: [],
    bodies: []
  }),
  computed: {
    Title() {
      return "個人シフト";
    }
  },
  methods: {
    close(result) {
      this.$store.commit("user/logout");
      this.resolve(result);
      this.$close();
    }
  },
  created() {
    console.log("created", this.args);
    this.headers = this.args.headers;
    this.bodies = this.args.bodies;
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>
